import React, { lazy, Suspense, useEffect } from "react";
import { Route, Routes as ReactRouterRoutes } from "react-router-dom";
import { IAuthGlobal, useAuthGlobal } from "../globals/AuthGlobal";
import RequireAuth from "./RequireAuth";
import RequirePublic from "./RequirePublic";

const Home = lazy(() => import("../views/Home/Home"));
const Category = lazy(() => import("../views/Category/Category"));
const Content = lazy(() => import("../views/Content/Content"));
const Author = lazy(() => import("../views/Author/Author"));
const NotFound = lazy(() => import("../views/NotFound/NotFound"));
const About = lazy(() => import("../views/About/About"));
const Tag = lazy(() => import("../views/Tag/Tag"));
const Policies = lazy(() => import("../views/Policies/Policies"));
const Agreement = lazy(() => import("../views/Agreement/Agreement"));
const Contact = lazy(() => import("../views/Contact/Contact"));
const SendContent = lazy(() => import("../views/SendContent/SendContent"));
const SendVideo = lazy(() => import("../views/SendVideo/SendVideo"));
const Authors = lazy(() => import("../views/Authors/Authors"));
const Signup = lazy(() => import("../views/Signup/Signup"));
const Login = lazy(() => import("../views/Login/Login"));
const UpdateInformation = lazy(() => import("../views/UpdateInformation/UpdateInformation"));
const MyLikes = lazy(() => import("../views/MyLikes/MyLikes"));
const ChangePassword = lazy(() => import("../views/ChangePassword/ChangePassword"));
const Logout = lazy(() => import("../views/Logout/Logout"));
const ForgotPassword = lazy(() => import("../views/ForgotPassword/ForgotPassword"));
const ResetPassword = lazy(() => import("../views/ResetPassword/ResetPassword"));
const Search = lazy(() => import("../views/Search/Search"));
const ArchiveYears = lazy(() => import("../views/Archive/ArchiveYears"));
const ArchiveYearMonths = lazy(() => import("../views/Archive/ArchiveYearMonths"));
const ArchiveYearMonthContents = lazy(() => import("../views/Archive/ArchiveYearMonthContents"));

function Routes() {
  const authGlobal = useAuthGlobal() as IAuthGlobal;

  useEffect(() => {
    // empty function just for getting the update on the field below
  }, [authGlobal.isAuthenticated]);

  const privateRoutes = [
    {
      path: "/uyelik/bilgilerim",
      component: UpdateInformation
    },
    {
      path: "/uyelik/begendiklerim",
      component: MyLikes
    },
    {
      path: "/uyelik/sifre-degistir",
      component: ChangePassword
    },
    {
      path: "/uyelik/cikis-yap",
      component: Logout
    }
  ];

  // This set of routes meant to be accessed only when logged out. The remaining(2) public routes are actually public.
  // Basically, this is more like a "Not Auth Required".
  const publicRoutes1 = [
    {
      path: "/uyelik/sifre-sifirla",
      component: ResetPassword
    },
    {
      path: "/uyelik/kayit-ol",
      component: Signup
    },
    {
      path: "/uyelik/giris-yap",
      component: Login
    },
    {
      path: "/uyelik/sifremi-unuttum",
      component: ForgotPassword
    }
  ];

  const publicRoutes2 = [
    {
      path: "/icerikler/:contentSlug/:contentId",
      component: Content
    },
    {
      path: "/yazarlar/:authorSlug/:authorId",
      component: Author
    },
    {
      path: "/kategoriler/:categorySlug",
      component: Category
    },
    {
      path: "/hakkimizda",
      component: About
    },
    {
      path: "/kunye",
      component: Tag
    },
    {
      path: "/yayin-ilkeleri",
      component: Policies
    },
    {
      path: "/kullanici-sozlesmesi",
      component: Agreement
    },
    {
      path: "/iletisim",
      component: Contact
    },
    {
      path: "/arsiv/:year/:month",
      component: ArchiveYearMonthContents
    },
    {
      path: "/arsiv/:year",
      component: ArchiveYearMonths
    },
    {
      path: "/arsiv",
      component: ArchiveYears
    },
    {
      path: "/yazi-gonder",
      component: SendContent
    },
    {
      path: "/video-gonder",
      component: SendVideo
    },
    {
      path: "/yazarlar",
      component: Authors
    },
    {
      path: "/arama",
      component: Search
    },
    {
      path: "/",
      component: Home
    },
    {
      path: "/404",
      component: NotFound
    },
    {
      path: "*",
      component: NotFound
    }
  ];

  return (
    <Suspense fallback={<div>Loading..</div>}>
      <ReactRouterRoutes>
        {publicRoutes1.map(({ path, component: Comp }) => {
          return (
            <Route
              key={path}
              path={path}
              element={
                <RequirePublic isAuthenticated={authGlobal.isAuthenticated}>
                  <Comp />
                </RequirePublic>
              }
            />
          );
        })}
        {privateRoutes.map(({ path, component: Comp }) => {
          return (
            <Route
              key={path}
              path={path}
              element={
                <RequireAuth isAuthenticated={authGlobal.isAuthenticated}>
                  <Comp />
                </RequireAuth>
              }
            />
          );
        })}

        {publicRoutes2.map(({ path, component: Comp }) => {
          return <Route key={path} path={path} element={<Comp />} />;
        })}
      </ReactRouterRoutes>
    </Suspense>
  );
}

export default Routes;
