import React, { createContext, useContext, useEffect, useState } from "react";

export function EnvironmentGlobal({ children }: Props) {
  const [environment, setEnvironment] = useState<string>("");
  const [apiUrl, setApiUrl] = useState<string>("");
  const [clientUrl, setClientUrl] = useState<string>("");

  useEffect(() => {
    setEnvironment(process.env.REACT_APP_ENVIRONMENT ?? "");
    setApiUrl(process.env.REACT_APP_API_URL ?? "");
    setClientUrl(process.env.REACT_APP_CLIENT_URL ?? "");
  }, []);

  const value: IEnvironmentGlobal = {
    environment,
    apiUrl,
    clientUrl
  };

  return <EnvironmentContext.Provider value={value}>{children}</EnvironmentContext.Provider>;
}

export interface IEnvironmentGlobal {
  environment: string;
  apiUrl: string;
  clientUrl: string;
}

interface Props {
  children: React.ReactElement;
}

const EnvironmentContext = createContext<IEnvironmentGlobal | null>(null);

export function useEnvironmentGlobal() {
  return useContext(EnvironmentContext);
}
