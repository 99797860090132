import React from "react";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import { IMenuItem } from "./topbarNavigation";

function TopbarIconButton(props: Props) {
  return (
    <IconButton
      color="inherit"
      key={props.button.name}
      component={Link}
      to={String(props.button.slug)}
      size="large"
    >
      {props.button.icon}
    </IconButton>
  );
}

interface Props {
  button: IMenuItem;
}

export default TopbarIconButton;
