import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";

function RequirePublic(props: Props) {
  const render = !props.isAuthenticated ? props.children : <Navigate to="/" />;

  return <>{render}</>;
}

interface Props {
  isAuthenticated: boolean;
  children: ReactElement;
}

export default RequirePublic;
