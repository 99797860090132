import React, { createContext, useContext, useState } from "react";
import { CircularProgress, Dialog } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  dialogPaperPadding: {
    padding: "2rem"
  },

  backdrop: {
    backgroundColor: "rgba(0,0,0,0.2)"
  }
});

export function LoadingGlobal({ children }: Props) {
  const classes = useStyles();
  const [isLoadingOpen, setIsLoadingOpen] = useState(false);

  function handleOpen() {
    setIsLoadingOpen(true);
  }

  function handleClose() {
    setIsLoadingOpen(false);
  }

  const value: ILoadingGlobal = {
    open: handleOpen,
    close: handleClose
  };

  return (
    <LoadingContext.Provider value={value}>
      {children}
      <Dialog
        open={isLoadingOpen}
        slotProps={{
          backdrop: {
            invisible: false,
            className: classes.backdrop
          }
        }}
        PaperProps={{ elevation: 1, className: classes.dialogPaperPadding }}
      >
        <CircularProgress color="secondary" size={120} thickness={3.6} />
      </Dialog>
    </LoadingContext.Provider>
  );
}

export interface ILoadingGlobal {
  open: () => void;
  close: () => void;
}

interface Props {
  children: React.ReactElement;
}

const LoadingContext = createContext<ILoadingGlobal | null>(null);

export function useLoadingGlobal() {
  return useContext(LoadingContext);
}
