import React, { createContext, useContext, ReactElement } from "react";
import { useAxiosService } from "./AxiosService";
import { AxiosRequestConfig, CancelToken } from "axios";

const CategoriesContext = createContext<ICategoriesService | null>(null);

export function CategoriesService({ children }: Props) {
  const axios = useAxiosService();

  // Fetch featured categories
  function GetFeaturedCategories<T>(cancelToken: CancelToken): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: "categories/featured/contents",
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  // Fetch contents for a specific category
  function GetCategoryContents<T>(
    cancelToken: CancelToken,
    slug: string,
    page: number
  ): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `categories/${slug}/contents`,
      params: { page: page },
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  // Context value providing the API methods
  const value: ICategoriesService = {
    GetFeaturedCategories,
    GetCategoryContents
  };

  return <CategoriesContext.Provider value={value}>{children}</CategoriesContext.Provider>;
}

export interface ICategoriesService {
  GetFeaturedCategories: <T>(cancelToken: CancelToken) => Promise<T>;
  GetCategoryContents: <T>(cancelToken: CancelToken, slug: string, page: number) => Promise<T>;
}

interface Props {
  children: ReactElement;
}

// Custom hook to use the CategoriesService context
export function useCategoriesService(): ICategoriesService | null {
  return useContext(CategoriesContext);
}
