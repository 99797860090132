import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import TopbarButtons from "./TopbarButtons";
import { makeStyles } from "@mui/styles";
import { useMediaQuery, IconButton, Grid, Theme } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
// @ts-ignore
import logo from "../../../assets/images/oggito_logo.png";

const useStyles = makeStyles((theme: Theme) => ({
  appbar: {
    [theme.breakpoints.up("md")]: {
      padding: "0 1rem"
    }
  },
  logo: {
    [theme.breakpoints.up("md")]: {
      marginRight: "1rem"
    }
  },
  container: {
    flexGrow: 1,
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start"
    }
  }
}));

function Topbar({ onSidebarToggleClick }: Props) {
  const classes = useStyles();
  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const isMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <AppBar position="fixed" className={classes.appbar} elevation={0}>
      <Toolbar>
        <Grid container className={classes.container}>
          {!isMdUp && (
            <IconButton onClick={onSidebarToggleClick} color="inherit" size="large">
              <MenuIcon />
            </IconButton>
          )}
          <Button component={Link} to="/" className={classes.logo} color="inherit">
            <img width="105" src={logo} alt="Oggito Logo" />
          </Button>
          {!isMdDown && <TopbarButtons />}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Topbar;

// Type Definitions
interface Props {
  onSidebarToggleClick: () => void;
}
