import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { IMenuItem } from "./topbarNavigation";

function TopbarTextButton({ button }: Props) {
  return (
    <Button color="inherit" component={Link} to={String(button.slug)} key={button.name}>
      <Typography style={{ textTransform: "none" }} variant="body2">
        <b>{button.name}</b>
      </Typography>
    </Button>
  );
}

export default TopbarTextButton;

// Type Definitions
interface Props {
  button: IMenuItem;
}
