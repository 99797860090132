import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Canceler,
  CancelToken
} from "axios";
import { ISnackbarGlobal, useSnackbarGlobal } from "../globals/SnackbarGlobal";
import { IAuthGlobal, useAuthGlobal } from "../globals/AuthGlobal";
import { IAccountService, useAccountService } from "./AccountService";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const baseAxios: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 20000,
  responseType: "json",
  headers: { "Cache-Control": "no-cache" }
});

export function useAxiosService(): { send: <T>(httpRequest: AxiosRequestConfig) => Promise<T> } {
  const snackbarGlobal = useSnackbarGlobal() as ISnackbarGlobal;
  const authGlobal = useAuthGlobal() as IAuthGlobal;
  const accountService = useAccountService() as IAccountService;
  const [token, cancelToken] = useCancelToken();
  const navigate = useNavigate();

  useEffect(() => {
    return function cleanup() {
      cancelToken();
    };
  }, []);

  function send<T>(httpRequest: AxiosRequestConfig): Promise<T> {
    if (authGlobal === null) throw new Error("auth global is empty");

    const accessToken = authGlobal.getAccessToken();

    if (accessToken) baseAxios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    return new Promise((resolve, reject) => {
      baseAxios(httpRequest)
        .then((response: AxiosResponse<IApiHttpResponse<T>, any>) => resolve(response.data.data))
        .catch(async (error: AxiosError<IApiHttpResponse<T>, any>) => {
          let errorMessage;

          // Timeout & Cancellation errors
          if (!error.response) errorMessage = "İstek zaman aşımına uğradı veya iptal edildi.";
          // Authorization errors
          else if (error.response.status === 401) {
            const refreshToken = authGlobal.getRefreshToken();

            if (!refreshToken) {
              navigate("/uyelik/giris-yap");

              errorMessage = "Oturum zaman aşımına uğradı. Lütfen tekrar giriş yapınız.";
            } else {
              try {
                const getAccessTokenResponse = await accountService.GetAccessToken<{
                  refreshToken: string;
                  accessToken: string;
                }>(token, refreshToken);

                authGlobal.login(
                  getAccessTokenResponse.refreshToken,
                  getAccessTokenResponse.accessToken
                );

                baseAxios.defaults.headers.common.Authorization = `Bearer ${getAccessTokenResponse.accessToken}`;

                if (!error.config) {
                  console.log("error.config is empty!");
                  return;
                }

                error.config.headers.Authorization = `Bearer ${getAccessTokenResponse.accessToken}`;

                // Send original request one more time!
                return resolve(send(error.config));
              } catch (error) {
                errorMessage = "Oturum zaman aşımına uğradı. Lütfen tekrar giriş yapınız.";

                authGlobal.logout();

                navigate("/uyelik/giris-yap");
              }
            }
          }
          // Service handled errors
          else {
            errorMessage = error.response.data.errors[0].message;
          }

          // We don't show messages which are thrown by cancellation token.
          if (!axios.isCancel(error)) {
            snackbarGlobal.open(errorMessage);
          }

          return reject(errorMessage);
        });
    });
  }

  return { send };
}

export interface IApiHttpResponse<T> {
  data: T;
  info: string;
  errors: { type: string; message: string }[];
}

export function useCancelToken(): readonly [CancelToken, Canceler] {
  const cancelTokenSource = axios.CancelToken.source();

  return [cancelTokenSource.token, cancelTokenSource.cancel] as const;
}
