import React, { createContext, useContext, useEffect, useState, ReactElement } from "react";

export function AuthGlobal({ children }: Props) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const existingRefreshToken = getRefreshToken();
    const existingAccessToken = getAccessToken();

    if (existingRefreshToken && existingAccessToken) {
      login(existingRefreshToken, existingAccessToken);
    } else {
      logout();
    }
  }, []);

  function login(refreshToken: string, accessToken: string) {
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("accessToken", accessToken);
    setIsAuthenticated(true);
  }

  function logout() {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    setIsAuthenticated(false);
  }

  function getRefreshToken(): string | null {
    return localStorage.getItem("refreshToken");
  }

  function getAccessToken(): string | null {
    return localStorage.getItem("accessToken");
  }

  const value: IAuthGlobal = {
    getRefreshToken,
    getAccessToken,
    login,
    logout,
    isAuthenticated
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export interface IAuthGlobal {
  getRefreshToken: () => string | null;
  getAccessToken: () => string | null;
  login: (refreshToken: string, accessToken: string) => void;
  logout: () => void;
  isAuthenticated: boolean;
}

interface Props {
  children: ReactElement;
}

const AuthContext = createContext<IAuthGlobal | null>(null);

export function useAuthGlobal(): IAuthGlobal | null {
  return useContext(AuthContext);
}
