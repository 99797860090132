import React, { createContext, useContext, ReactElement } from "react";
import { useAxiosService } from "./AxiosService";
import { AxiosRequestConfig, CancelToken } from "axios";

export function UsersService({ children }: Props) {
  const axios = useAxiosService();

  function Get<T>(cancelToken: CancelToken): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: "users",
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  function UpdateInformation<T>(
    cancelToken: CancelToken,
    firstName: string,
    lastName: string,
    city: string
  ): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "put",
      url: "users",
      data: { firstName, lastName, city },
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  function UpdatePassword<T>(
    cancelToken: CancelToken,
    oldPassword: string,
    newPassword: string
  ): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "patch",
      url: "users",
      data: { oldPassword, newPassword },
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  function GetContents<T>(cancelToken: CancelToken): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: "users/contents",
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  function AddContent<T>(cancelToken: CancelToken, contentId: number): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `users/contents/${contentId}`,
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  function DeleteContent<T>(cancelToken: CancelToken, contentId: number): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "delete",
      url: `users/contents/${contentId}`,
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  const value: IUsersService = {
    Get,
    UpdateInformation,
    UpdatePassword,
    GetContents,
    AddContent,
    DeleteContent
  };

  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>;
}

export function useUsersService(): IUsersService | null {
  return useContext(UsersContext);
}

export interface IUsersService {
  Get: <T>(cancelToken: CancelToken) => Promise<T>;
  UpdateInformation: <T>(
    cancelToken: CancelToken,
    firstName: string,
    lastName: string,
    city: string
  ) => Promise<T>;
  UpdatePassword: <T>(
    cancelToken: CancelToken,
    oldPassword: string,
    newPassword: string
  ) => Promise<T>;
  GetContents: <T>(cancelToken: CancelToken) => Promise<T>;
  AddContent: <T>(cancelToken: CancelToken, contentId: number) => Promise<T>;
  DeleteContent: <T>(cancelToken: CancelToken, contentId: number) => Promise<T>;
}

interface Props {
  children: ReactElement;
}

const UsersContext = createContext<IUsersService | null>(null);
