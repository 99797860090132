import React, { createContext, useContext, ReactElement } from "react";
import { useAxiosService } from "./AxiosService";
import { AxiosRequestConfig, CancelToken } from "axios";

export function ArchiveService({ children }: Props) {
  const axios = useAxiosService();

  function GetYears<T>(cancelToken: CancelToken): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: "archive/years",
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  function GetYearMonths<T>(
    cancelToken: CancelToken,
    year: number | string | undefined
  ): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `archive/years/${year}`,
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  function GetYearMonthContents<T>(
    cancelToken: CancelToken,
    year: number | string | undefined,
    month: number | string | undefined
  ): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `archive/years/${year}/months/${month}`,
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  const value: IArchiveService = {
    GetYears,
    GetYearMonths,
    GetYearMonthContents
  };

  return <ArchiveContext.Provider value={value}>{children}</ArchiveContext.Provider>;
}

export interface IArchiveService {
  GetYears: <T>(cancelToken: CancelToken) => Promise<T>;
  GetYearMonths: <T>(cancelToken: CancelToken, year: number | string | undefined) => Promise<T>;
  GetYearMonthContents: <T>(
    cancelToken: CancelToken,
    year: number | string | undefined,
    month: number | string | undefined
  ) => Promise<T>;
}

interface Props {
  children: ReactElement;
}

const ArchiveContext = createContext<IArchiveService | null>(null);

export function useArchiveService(): IArchiveService | null {
  return useContext(ArchiveContext);
}
