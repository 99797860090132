import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";

function RequireAuth(props: Props) {
  const render = props.isAuthenticated ? props.children : <Navigate to="/uyelik/giris-yap" />;

  return <>{render}</>;
}

interface Props {
  isAuthenticated: boolean;
  children: ReactElement;
}

export default RequireAuth;
