import React, { createContext, useContext, ReactNode } from "react";
import { useAxiosService } from "./AxiosService";
import { AxiosRequestConfig, CancelToken } from "axios";

// Context provider for ContentsService
export function ContentsService({ children }: Props) {
  const axios = useAxiosService();

  // Fetch featured contents
  function GetFeaturedContents<T>(cancelToken: CancelToken): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: "contents/featured",
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  // Fetch home timeline contents
  function GetHomeTimelineContents<T>(cancelToken: CancelToken, page: number): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: "contents/home",
      params: { page: page },
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  // Fetch interesting contents
  function GetInterestingContents<T>(cancelToken: CancelToken): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: "contents/interesting",
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  // Fetch a specific content by contentId
  function GetContent<T>(cancelToken: CancelToken, contentId: number): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `contents/${contentId}`,
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  // Fetch search results for contents
  function GetSearchContents<T>(cancelToken: CancelToken, query: string, page: number): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: "contents/search",
      params: { query: query, page: page },
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  // Add a comment to a specific content
  function AddContentComment<T>(
    cancelToken: CancelToken,
    contentId: number,
    commentText: string
  ): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `contents/${contentId}/comments`,
      data: { commentText: commentText },
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  // Context value providing the API methods
  const value: IContentsService = {
    GetFeaturedContents,
    GetHomeTimelineContents,
    GetInterestingContents,
    GetSearchContents,
    GetContent,
    AddContentComment
  };

  return <ContentsContext.Provider value={value}>{children}</ContentsContext.Provider>;
}

// Custom hook to use the ContentsService context
export function useContentsService(): IContentsService | null {
  return useContext(ContentsContext);
}

// Interface for ContentsService methods
export interface IContentsService {
  GetFeaturedContents: <T>(cancelToken: CancelToken) => Promise<T>;
  GetHomeTimelineContents: <T>(cancelToken: CancelToken, page: number) => Promise<T>;
  GetInterestingContents: <T>(cancelToken: CancelToken) => Promise<T>;
  GetContent: <T>(cancelToken: CancelToken, contentId: number) => Promise<T>;
  GetSearchContents: <T>(cancelToken: CancelToken, query: string, page: number) => Promise<T>;
  AddContentComment: <T>(
    cancelToken: CancelToken,
    contentId: number,
    commentText: string
  ) => Promise<T>;
}

// Props interface for ContentsService component
interface Props {
  children: ReactNode;
}

const ContentsContext = createContext<IContentsService | null>(null);
