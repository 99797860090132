import React, { createContext, useContext, useState } from "react";
import { Snackbar } from "@mui/material";

export function SnackbarGlobal({ children }: Props) {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  function handleSnackbarOpen(message: string) {
    setMessage(message);
    setIsSnackbarOpen(true);
  }

  function handleSnackbarClose() {
    setIsSnackbarOpen(false);
    setMessage(null);
  }

  const value: ISnackbarGlobal = {
    open: handleSnackbarOpen,
    close: handleSnackbarClose
  };

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <Snackbar
        open={isSnackbarOpen}
        message={message}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      />
    </SnackbarContext.Provider>
  );
}

export interface ISnackbarGlobal {
  open: (message: string) => void;
  close: () => void;
}

interface Props {
  children: React.ReactElement;
}

const SnackbarContext = createContext<ISnackbarGlobal | null>(null);

export function useSnackbarGlobal() {
  return useContext(SnackbarContext);
}
