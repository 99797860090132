import React, { createContext, ReactElement, useContext } from "react";
import { useAxiosService } from "./AxiosService";
import { AxiosRequestConfig, CancelToken } from "axios";

export function AdvertisementService(props: Props) {
  const axios = useAxiosService();

  function Update<T>(cancelToken: CancelToken, advertisementId: number): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "patch",
      url: `adm/${advertisementId}`,
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  const value: IAdvertisementService = {
    Update: Update
  };

  return (
    <AdvertisementContext.Provider value={value}>{props.children}</AdvertisementContext.Provider>
  );
}

interface Props {
  children: ReactElement;
}

export interface IAdvertisementService {
  Update: <T>(cancelToken: CancelToken, advertisementId: number) => Promise<T>;
}

const AdvertisementContext = createContext<IAdvertisementService | null>(null);

export function useAdvertisementService(): IAdvertisementService | null {
  return useContext(AdvertisementContext);
}
