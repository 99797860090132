import React, { createContext, ReactElement, useContext } from "react";
import { useAxiosService } from "./AxiosService";
import { AxiosRequestConfig, CancelToken } from "axios";

export function AccountService(props: Props) {
  const axios = useAxiosService();

  function Signup<T>(
    cancelToken: CancelToken,
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    city: string,
    gender: string
  ): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: "account/signup",
      data: {
        email: email,
        password: password,
        firstName: firstName,
        lastName: lastName,
        city: city,
        gender: gender
      },
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  function Login<T>(cancelToken: CancelToken, email: string, password: string): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: "account/login",
      data: {
        email: email,
        password: password
      },
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  function GetAccessToken<T>(cancelToken: CancelToken, refreshToken: string): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: "account/access-token",
      data: {
        refreshToken: refreshToken
      },
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  function SendPasswordReset<T>(cancelToken: CancelToken, email: string): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: "account/send-password-reset",
      data: {
        email: email
      },
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  function ResetPassword<T>(
    cancelToken: CancelToken,
    userId: string,
    passwordResetToken: string,
    newPassword: string
  ): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: "account/reset-password",
      data: {
        userId: userId,
        passwordResetToken: passwordResetToken,
        newPassword: newPassword
      },
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  const value: IAccountService = {
    Signup: Signup,
    Login: Login,
    GetAccessToken: GetAccessToken,
    SendPasswordReset: SendPasswordReset,
    ResetPassword: ResetPassword
  };

  return <AccountContext.Provider value={value}>{props.children}</AccountContext.Provider>;
}

interface Props {
  children: ReactElement;
}

export interface IAccountService {
  Login: <T>(cancelToken: CancelToken, email: string, password: string) => Promise<T>;
  GetAccessToken: <T>(cancelToken: CancelToken, refreshToken: string) => Promise<T>;
  Signup: <T>(
    cancelToken: CancelToken,
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    city: string,
    gender: string
  ) => Promise<T>;
  SendPasswordReset: <T>(cancelToken: CancelToken, email: string) => Promise<T>;
  ResetPassword: <T>(
    cancelToken: CancelToken,
    userId: string,
    passwordResetToken: string,
    newPassword: string
  ) => Promise<T>;
}

const AccountContext = createContext<IAccountService | null>(null);

export function useAccountService(): IAccountService | null {
  return useContext(AccountContext);
}
