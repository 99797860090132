import React, { createContext, useContext, ReactElement } from "react";
import { useAxiosService } from "./AxiosService";
import { AxiosRequestConfig, CancelToken } from "axios";

export function AuthorsService({ children }: Props) {
  const axios = useAxiosService();

  // Fetch featured authors contents
  function GetFeaturedAuthorsContents<T>(cancelToken: CancelToken): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: "authors/featured/contents",
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  // Fetch Oggito authors
  function GetOggitoAuthors<T>(cancelToken: CancelToken): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: "authors/oggito",
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  // Fetch Oggito authors contents
  function GetOggitoAuthorsContents<T>(cancelToken: CancelToken): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: "authors/oggito/contents",
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  // Fetch contents for a specific author
  function GetAuthorContents<T>(
    cancelToken: CancelToken,
    authorId: number,
    page: number
  ): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `authors/${authorId}/contents`,
      params: { page: page },
      cancelToken: cancelToken
    };

    return axios.send<T>(config);
  }

  // Context value providing the API methods
  const value: IAuthorsService = {
    GetFeaturedAuthorsContents,
    GetOggitoAuthorsContents,
    GetAuthorContents,
    GetOggitoAuthors
  };

  return <AuthorsContext.Provider value={value}>{children}</AuthorsContext.Provider>;
}

export interface IAuthorsService {
  GetFeaturedAuthorsContents: <T>(cancelToken: CancelToken) => Promise<T>;
  GetOggitoAuthorsContents: <T>(cancelToken: CancelToken) => Promise<T>;
  GetAuthorContents: <T>(cancelToken: CancelToken, authorId: number, page: number) => Promise<T>;
  GetOggitoAuthors: <T>(cancelToken: CancelToken) => Promise<T>;
}

interface Props {
  children: ReactElement;
}

const AuthorsContext = createContext<IAuthorsService | null>(null);

// Custom hook to use the AuthorsService context
export function useAuthorsService(): IAuthorsService | null {
  return useContext(AuthorsContext);
}
