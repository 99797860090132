import React, { lazy, Suspense, useState } from "react";
import Topbar from "./Topbar/Topbar";
import { useMediaQuery } from "@mui/material";

const Sidebar = lazy(() => import("./Sidebar/Sidebar"));

function Navigation() {
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);

  function handleSidebarToggle() {
    setSidebarOpen((isOpen) => !isOpen);
  }

  const hidden = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <>
      <Topbar onSidebarToggleClick={handleSidebarToggle} />
      {!hidden && (
        <Suspense fallback={<div />}>
          <Sidebar onSidebarClose={handleSidebarToggle} isOpen={isSidebarOpen} />
        </Suspense>
      )}
    </>
  );
}

export default Navigation;
