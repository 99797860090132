import React, { useEffect, useState } from "react";
import TopbarMenuButton from "./TopbarMenuButton";
import TopbarIconButton from "./TopbarIconButton";
import TopbarTextButton from "./TopbarTextButton";
import Grid from "@mui/material/Grid2";
import { categories, authenticatedMenu, notAuthenticatedMenu, IMenuItem } from "./topbarNavigation";
import { IAuthGlobal, useAuthGlobal } from "../../../globals/AuthGlobal";

function TopbarButtons() {
  const [menuItems, setMenuItems] = useState<IMenuItem[]>([]);
  const authGlobal = useAuthGlobal() as IAuthGlobal;

  useEffect(() => {
    if (authGlobal.isAuthenticated) setMenuItems(authenticatedMenu);
    else setMenuItems(notAuthenticatedMenu);
  }, [authGlobal.isAuthenticated]);

  const categoryButtons = categories.map((b) =>
    b.children && b.children.length > 0 ? (
      <TopbarMenuButton key={b.name} button={b} />
    ) : (
      <TopbarTextButton key={b.name} button={b} />
    )
  );

  const menuButtons = menuItems.map((b) =>
    b.children && b.children.length > 0 ? (
      <TopbarMenuButton key={b.name} button={b} />
    ) : (
      <TopbarIconButton key={b.name} button={b} />
    )
  );

  return (
    <Grid
      container={true}
      sx={{ alignItems: "center", justifyContent: "space-between", flexGrow: 1 }}
    >
      <Grid>{categoryButtons}</Grid>
      <Grid>{menuButtons}</Grid>
    </Grid>
  );
}

export default TopbarButtons;
