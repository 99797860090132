import React, { createContext, useContext } from "react";

export interface IFacebookGlobal {
  share: (url: string) => void;
}

const FacebookContext = createContext<IFacebookGlobal | null>(null);

export function useFacebookGlobal(): IFacebookGlobal | null {
  return useContext(FacebookContext);
}

export function FacebookGlobal({ children }: Props) {
  function share(url: string) {
    // @ts-ignore
    window.FB.ui(
      {
        method: "share",
        href: url
      },
      function (response: any) {
        return;
      }
    );
  }

  const value: IFacebookGlobal = {
    share
  };

  return <FacebookContext.Provider value={value}>{children}</FacebookContext.Provider>;
}

interface Props {
  children: React.ReactElement;
}
