import React, { lazy, Suspense } from "react";
import Routes from "./routes/Routes";
import { BrowserRouter } from "react-router-dom";
import Services from "./services/Services";
import Grid from "@mui/material/Grid2";
import Navigation from "./components/Navigation/Navigation";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./utility/Themes/theme";

const Footer = lazy(() => import("./components/Footer/Footer"));
const ScrollToTop = lazy(() => import("./utility/ScrollToTop"));

const useStyles = makeStyles({
  container: {
    minHeight: "100%",
    paddingTop: "4.5rem"
  }
});

function App() {
  const classes = useStyles();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Suspense fallback={<div />}>
            <ScrollToTop />
          </Suspense>
          <CssBaseline />
          <Services>
            <Navigation />
            <Grid
              container={true}
              spacing={0}
              direction="column"
              className={classes.container}
              sx={{
                justifyContent: "space-between"
              }}
            >
              <Grid>
                <Routes />
              </Grid>
              <Grid>
                <Suspense fallback={<div />}>
                  <Footer />
                </Suspense>
              </Grid>
            </Grid>
          </Services>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
