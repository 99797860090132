import React from "react";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { IMenuItem as IButtonMenuItem } from "./topbarNavigation";

function TopbarMenuButtonItem({ button, onClick }: Props) {
  return (
    <>
      <MenuItem component={Link} to={String(button.slug)} onClick={onClick} key={button.name}>
        <Typography variant="body2">
          <b>{button.name}</b>
        </Typography>
      </MenuItem>
      {button.children?.map((child) => (
        <MenuItem component={Link} to={String(child.slug)} onClick={onClick} key={child.name}>
          <Typography variant="body2">
            <b>- {child.name}</b>
          </Typography>
        </MenuItem>
      ))}
    </>
  );
}

export default TopbarMenuButtonItem;

// Type Definitions
interface Props {
  button: IButtonMenuItem;
  onClick: () => void;
}
